<!-- Package Selection Phase -->
<ng-container *ngIf="currentPhase === 'selection'" class="max-h-full">
  <h2 mat-dialog-title>Choose your packages</h2>
  <mat-dialog-content>
    <div class="mb-4">
      <h3 class="text-lg font-semibold mb-4">Select packages for {{ data.length }} {{ data.length === 1 ? 'company' : 'companies' }}</h3>
      <div class="flex flex-col md:flex-row lg:p-5 lg:justify-around space-x-4">
        <ng-container *ngFor="let package of packages">
          <div class="flex bg-gray-50 border rounded-lg overflow-hidden shadow-xl my-2 md:w-1/3"
               [class.border-primary]="isPackageSelected(package)">
            <div class="flex flex-col justify-between w-full">
              <div class="p-6">
                <div class="text-h2 font-semibold text-gray-700 py-1 text-center md:text-left h-10">{{ package.name }}</div>
                <div class="mt-6 w-full">
                  <div class="text-black text-xl font-normal py-2 w-full">${{ package.price }}</div>
                </div>
                <ul class="min-h-72">
                  <ng-container *ngFor="let feature of package.features">
                    <li class="mt-4 text-sm text-primary text-center md:text-left">{{ feature }}</li>
                  </ng-container>
                </ul>
              </div>
              <div class="mt-6 w-full">
                <button class="flex justify-center items-center w-full py-2 px-4 text-white transition-colors"
                  [class]="isPackageSelected(package) ? 'bg-primary hover:bg-primary/90' : 'bg-secondary hover:bg-secondary/90'"
                  (click)="togglePackage(package)">
                  <mat-icon [class.opacity-30]="!isPackageSelected(package)">
                    {{ isPackageSelected(package) ? 'check_circle' : 'radio_button_unchecked' }}
                  </mat-icon>
                  <span class="ml-2">{{ isPackageSelected(package) ? 'Selected' : 'Select' }}</span>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="flex">
    <button mat-button mat-dialog-close>Close</button>
    <button 
            color="primary" 
            class="btn ml-auto mr-5 cursor-pointer w-[139px] mt-[6px] text-center font-poppins text-base font-semibold bg-secondary text-white px-[5px] py-[6px] leading-8" 
            *ngIf="selectedPackages.length > 0"
            (click)="goToConfirmation()">
      Continue
    </button>
  </mat-dialog-actions>
</ng-container>

<!-- Confirmation Phase -->
<ng-container *ngIf="currentPhase === 'confirmation'">
  <h2 mat-dialog-title>Confirm Order</h2>
  <mat-dialog-content>
    <div class="flex flex-col lg:flex-row">
      <div [class]="this.formGroup.getRawValue().xAmount === 0 ? 'w-full' : 'lg:w-1/2'" class="h-auto bg-gray-100 flex flex-col items-center p-2 lg:p-10">
        <!-- Selected Companies -->
        <div class="w-full mb-8">
          <div class="text-xl font-semibold mb-2 text-primary">Companies</div>
          <div class="bg-white rounded-lg shadow-lg p-4">
            <ul>
              <li *ngFor="let company of data">
                <span class="font-medium">{{ company.name }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Selected Packages -->
        <div class="w-full mb-8">
          <div class="text-xl font-semibold mb-2 text-primary">Packages</div>
          <div class="bg-white rounded-lg shadow-lg p-4">
            <ul class="space-y-2">
              <li *ngFor="let package of selectedPackages">
                <div class="flex justify-between items-center">
                  <span class="font-medium">{{ package.name }}</span>
                  <span class="text-gray-600">${{ package.price }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Single UBO Option -->
        <div class="w-full mb-4" *ngIf="hasUboAvailable()">
          <div class="flex items-center p-4 border border-black rounded-lg">
            <div class="ml-4 flex flex-col">
              <div class="flex mb-2 items-center -ml-3">
                <mat-checkbox 
                  class="mr-1" 
                  color="primary"
                  [(ngModel)]="uboSelected" 
                  (change)="updateTotalAmount()">
                </mat-checkbox>
                <span class="font-bold m-0 text-black">UBO discovery</span>
              </div>
              <span class="m-0 text-[12px]">Identifying the Ultimate Beneficial Owner (UBO) is crucial for enforcing a
                judgment to comply with your Due Diligence policies...</span>
              <span class="mt-2 text-[12px] text-gray-600">
                * Available for packages: {{ uboSupportedPackageNames }}
              </span>
            </div>
          </div>
        </div>

        <hr class="border-t border-gray-300 my-4 w-full mt-4"/>
        <!-- Add this inside the confirmation phase, before the payment section -->
        <div class="w-full mb-8">
          <h3 class="text-xl font-semibold mb-4">Order Summary</h3>
          <div class="bg-white rounded-lg shadow-lg p-4">
            <div class="grid gap-3">
              <!-- Base Reports -->
              <div class="flex justify-between items-center">
                <span>Reports x {{orderSummary.totalReports}}</span>
                <span>${{getFullReportPrice()}}</span>
              </div>

              <!-- Credits Applied - Only show if credits are available -->
              <div class="flex justify-between items-center text-green-600" *ngIf="orderSummary.availableCredits > 0">
                <span>Applied credits x {{orderSummary.totalReports}} 
                  <span class="text-sm text-gray-500">
                    ({{orderSummary.availableCredits}} credits available)
                  </span>
                </span>
                <span>-${{getCreditDeduction()}}</span>
              </div>

              <!-- UBO if selected -->
              <div class="flex justify-between items-center" *ngIf="uboSelected && hasUboAvailable()">
                <span>UBO reports x {{getUboSupportedPackages().length * data.length}}</span>
                <span>${{orderSummary.uboTotal}}</span>
              </div>

              <hr class="my-2"/>
              
              <!-- Total -->
              <div class="flex justify-between font-bold text-lg">
                <span>Total Due:</span>
                <span>${{orderSummary.totalPaymentNeeded}}</span>
              </div>
              
              <!-- Additional Info - Only show if credits are being used -->
              <div class="text-sm text-gray-500 mt-2" *ngIf="orderSummary.creditsToUse > 0">
                * Credits are applied to the most expensive reports first
              </div>
              <div class="text-sm text-gray-500" *ngIf="uboSelected && hasUboAvailable()">
                * UBO services are charged for supported packages only
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="this.formGroup.getRawValue().xAmount === 0">
          <button mat-raised-button 
                  class="w-full mt-4" 
                  (click)="submitDiligence()">
            Submit
          </button>
        </ng-container>
      </div>

      <div class="lg:w-1/2" *ngIf="this.formGroup.getRawValue().xAmount > 0">
        <ng-container>
          <form [formGroup]="formGroup" (ngSubmit)="onPaySubmit()">
            <div class="flex flex-col m-4">
              <p class="mt-20 text-2xl font-bold">Payment</p>
              <mat-form-field appearance="outline">
                <mat-label>Card Number</mat-label>
                <input matInput formControlName="xCardNum">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Expiration Date (MM/YY):</mat-label>
                <input appExpiryDateFormatter matInput formControlName="xExp">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>CVV</mat-label>
                <input matInput formControlName="xCVV">
              </mat-form-field>
              <p class="mt-20 text-2xl font-bold">Billing Info</p>
              <mat-form-field class="companyNameForm" appearance="outline">
                <mat-label>First Name:</mat-label>
                <input matInput formControlName="xBillFirstName">
              </mat-form-field>
              <mat-form-field class="companyNameForm" appearance="outline">
                <mat-label>Last Name:</mat-label>
                <input matInput formControlName="xBillLastName">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Street:</mat-label>
                <input matInput formControlName="xBillStreet">
              </mat-form-field> <mat-form-field appearance="outline">
                <mat-label>City:</mat-label>
                <input matInput formControlName="xBillCity">
              </mat-form-field> <mat-form-field appearance="outline">
                <mat-label>State:</mat-label>
                <input matInput formControlName="xBillState">
              </mat-form-field> <mat-form-field appearance="outline">
                <mat-label>Zip:</mat-label>
                <input matInput formControlName="xBillZip">
              </mat-form-field> <mat-form-field appearance="outline">
                <mat-label>Mobile:</mat-label>
                <input matInput formControlName="xBillMobile">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <input matInput formControlName="xAmount">
              </mat-form-field>

              <div class="flex flex-col m-4">
                <mat-checkbox color="primary" formControlName="termsAccepted">
                  I accept the <a href="javascript:void(0);" class="underline text-blue-600" (click)="openTermsAndConditions()">Terms Of Sale</a>
                </mat-checkbox>
                <mat-error *ngIf="formGroup.get('termsAccepted')?.touched && formGroup.get('termsAccepted')?.errors?.['required']">
                  You must accept the Terms Of Sale to proceed.
                </mat-error>
              </div>
              <button mat-raised-button type="submit" class="text-center">Pay and Submit</button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="goBackToSelection()">Back to Selection</button>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</ng-container>

<div class="flex justify-center pt-5">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>

<div class="successContainer" *ngIf="this.userHasPayed">
  <mat-icon class="text-green-500">check_circle</mat-icon>
  <span class="text-green-500">Transaction approved successfully!</span>
</div>

<div *ngIf="this.error">
  <mat-icon class="text-red">check_circle</mat-icon>
  <span class="text-red">We were unable to process your payment. Please verify your payment details and try again.
    If
    the problem persists, consider using an alternative payment method or contact our support team for
    assistance.</span>
</div>