import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { Credit, CreateCreditDto } from '../../../core/models/credit';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-credits',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    ReactiveFormsModule,
    MatDividerModule
  ],
  template: `
    <div class="credits-container">
      <ng-container *ngIf="credits.length > 0">
        <table mat-table [dataSource]="credits" class="w-full mb-4">
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Remaining Amount </th>
            <td mat-cell *matCellDef="let credit">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput type="number" [(ngModel)]="credit.remaining_amount">
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let credit">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput [matDatepicker]="startPicker" [(ngModel)]="credit.startDate">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef> End Date </th>
            <td mat-cell *matCellDef="let credit">
              <mat-form-field appearance="outline" class="w-full">
                <input matInput [matDatepicker]="endPicker" [(ngModel)]="credit.endDate">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let credit">
              <button mat-button color="primary" class="mb-5" (click)="updateCredit(credit)">Update</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-divider></mat-divider>
      </ng-container>

      <h3 class="text-lg font-semibold my-4">Add Credit</h3>
      <form [formGroup]="creditForm" (ngSubmit)="addCredit()" class="flex flex-col space-y-4">
        <div class="flex space-x-4">
          <mat-form-field appearance="outline" class="flex-1">
            <mat-label>Amount</mat-label>
            <input matInput type="number" formControlName="amount" placeholder="Amount" required>
            <mat-error *ngIf="creditForm.get('amount')?.hasError('required')">Amount is required</mat-error>
            <mat-error *ngIf="creditForm.get('amount')?.hasError('min')">Amount must be positive</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-1">
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="addStartPicker" formControlName="startDate" placeholder="Start Date">
            <mat-datepicker-toggle matSuffix [for]="addStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #addStartPicker></mat-datepicker>
            <mat-error *ngIf="creditForm.get('startDate')?.hasError('required')">Start date is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="flex-1">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="addEndPicker" formControlName="endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="addEndPicker"></mat-datepicker-toggle>
            <mat-datepicker #addEndPicker></mat-datepicker>
            <mat-error *ngIf="creditForm.get('endDate')?.hasError('required')">End date is required</mat-error>
          </mat-form-field>
        </div>

        <button mat-raised-button color="primary" type="submit" [disabled]="!creditForm.valid">
          Add Credit
        </button>
      </form>
    </div>
  `

})
export class CreditsComponent {
  @Input() entityType!: 'user' | 'organization';
  @Input() entityId!: string;

  creditForm: FormGroup;
  credits: Credit[] = [];
  displayedColumns: string[] = ['amount', 'startDate', 'endDate', 'actions'];
  private originalCredits: Credit[] = [];

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder
  ) {
    this.creditForm = this.fb.group({
      amount: ['', [Validators.required, Validators.min(0)]],
      startDate: [''],
      endDate: ['']
    });
  }

  ngOnInit() {
    this.loadCredits();
  }

  addCredit() {
    if (this.creditForm.valid) {
      const creditData: CreateCreditDto = {
        amount: this.creditForm.value.amount,
        entityType: this.entityType,
        entityId: String(this.entityId),
        ...(this.creditForm.value.startDate && { startDate: this.creditForm.value.startDate }),
        ...(this.creditForm.value.endDate && { endDate: this.creditForm.value.endDate })
      };
      
      this.apiService.addCredit(creditData).subscribe(() => {
        this.loadCredits();
        this.creditForm.reset();
      });
    }
  }

  loadCredits() {
    this.apiService.listCreditsForEntity(this.entityType, this.entityId).subscribe({
      next: (credits) => {
        this.credits = credits;
        this.originalCredits = JSON.parse(JSON.stringify(credits));
      },
      error: (error) => {
        console.error('Error loading credits:', error);
      }
    });
  }

  updateCredit(credit: Credit) {
    const originalCredit = this.originalCredits.find(c => c.id === credit.id);
    const amountDifference = credit.remaining_amount - (originalCredit?.remaining_amount || 0);
    const updatedCredit = {
      ...credit,
      amount: amountDifference + (originalCredit?.amount || 0)
    };

    this.apiService.updateCredit(updatedCredit).subscribe(() => {
      this.loadCredits();
    });
  }
} 