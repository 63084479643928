<app-navbar></app-navbar>

<div class="mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4 p-4">Accounts</h1>

    <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Users">
            <table mat-table [dataSource]="users" class="w-full">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>User</th>
                    <td mat-cell *matCellDef="let user">
                        <div class="flex items-center gap-2">
                            {{user.email}} <span *ngIf="user.name">({{user.name}})</span>
                            <span *ngIf="user.organizations && user.organizations.length > 0" 
                                  class="px-2 py-1 text-sm rounded-full bg-blue-100 text-blue-800">
                                {{user.organizations[0].name}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let user">
                        <button *ngIf="user.organizations.length === 0" 
                                mat-button 
                                color="primary" 
                                (click)="openCreditDialog(user)">
                            Manage Credits
                        </button>
                        <span *ngIf="user.organizations.length > 0" 
                              class="text-gray-600 italic text-sm">
                            Credits managed via organization
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator
                [length]="totalUsers"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="handlePageEvent($event)"
                aria-label="Select page">
            </mat-paginator>
            
            <button mat-raised-button
                    color="secondary"
                    (click)="openEmailCreditsDialog()"
                    class="fixed bottom-16 right-8 bg-secondary border border-2 border-primary text-white rounded-full p-6"
                    aria-label="Add Organization">
                <mat-icon>add</mat-icon>
                <span>Add Credits by Email</span>
            </button>
        </mat-tab>
        <mat-tab label="Organizations">
            <table mat-table [dataSource]="organizations" class="w-full cursor-pointer">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let org">{{org.name}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsOrg"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsOrg;" 
                    (click)="viewOrganization(row)"
                    class="hover:bg-gray-100 transition-colors duration-200">
                </tr>
            </table>

            <mat-paginator
                [length]="totalOrganizations"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="handlePageEvent($event)"
                aria-label="Select page">
            </mat-paginator>
            
            <button mat-raised-button
                    color="secondary"
                    (click)="createOrganization()"
                    class="fixed bottom-16 right-8 bg-secondary border border-2 border-primary text-white rounded-full p-6"
                    aria-label="Add Organization">
                <mat-icon>add</mat-icon>
                <span>Add Organization</span>
            </button>
        </mat-tab>
    </mat-tab-group>
</div>