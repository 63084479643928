<div class="tableContainer" *ngIf="companies?.length">
   
    <mat-table multiTemplateDataRows [dataSource]="companies">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let company">
                <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(company) : null"
                            [checked]="selection.isSelected(company)"
                            [aria-label]="checkboxLabel(company)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="regNo">
            <mat-header-cell *matHeaderCellDef> Company Number </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.regNo }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef> V number </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.safeNo }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.address?.simpleValue }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="locationType">
            <mat-header-cell *matHeaderCellDef> Location Type </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.officeType }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyStatus">
            <mat-header-cell *matHeaderCellDef> Company Status </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.status }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>&nbsp;</mat-header-cell>
            <mat-cell *matCellDef="let company">
                <button mat-button color="primary" (click)="getDiligence(company, 'COMPANY')">
                    Get Report
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <div class="flex justify-left items-center border-t border-b border-gray-300 p-4">
        <span class="text-black">
            <strong>Can't find what you're looking for?</strong> provide us the details and our advanced AI will
            find
            it.
        </span>
        <button mat-button class="bg-blue-500 text-white rounded px-2 py-1 ml-8" (click)="redirectToSearch()">
            Get Started
        </button>
    </div>
</div>
<mat-paginator [length]="100" [pageSize]="pageSize" [pageIndex]="page" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="this.getCompanies.emit($event)" *ngIf="companies?.length || 0 > pageSize" aria-label="Select page">
</mat-paginator>

<div class="tableContainer" *ngIf="individuals?.length">
    <mat-table [dataSource]="individuals" multiTemplateDataRows class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let individual">
                <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(individual) : null"
                            [checked]="selection.isSelected(individual)"
                            [aria-label]="checkboxLabel(individual)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.firstName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.lastName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.country }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.company?.companyName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.address?.simpleValue }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef> V number </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.peopleId }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let individual">
                <button mat-button color="primary" (click)="getDiligence(individual, 'INDIVIDUAL')">
                    Get Report
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplayIndividuals"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplayIndividuals"></mat-row>

    </mat-table>
    <div class="flex justify-left items-center border-t border-b border-gray-300 p-4">
        <span class="text-black">
            <strong>Can't find what you're looking for?</strong> provide us the details and our advanced AI will
            find
            it.
        </span>
        <button mat-button class="bg-blue-500 text-white rounded px-2 py-1 ml-8" (click)="redirectToSearch()">
            Get Started
        </button>
    </div>
    <mat-paginator [length]="100" [pageSize]="pageSize" [pageIndex]="page" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="this.getIndividuals.emit($event)" *ngIf="companies?.length || 0 > pageSize" aria-label="Select page">
    </mat-paginator>
</div>

<!-- Fixed bottom bar when items are selected -->
<div *ngIf="selection.hasValue()" class="fixed bottom-0 left-0 right-0 bg-white z-10 shadow-lg bg-white border-t border-gray-200 p-4 flex justify-between items-center">
    <div class="flex items-center">
        <span class="font-semibold">{{ selection.selected.length }} {{ selection.selected.length === 1 ? 'item' : 'items' }} selected</span>
    </div>
    <button class="btn cursor-pointer w-[139px] mt-[6px] text-center font-poppins text-base font-semibold bg-secondary text-white px-[5px] py-[6px] leading-8" color="primary" (click)="getDiligence(selection.selected[0], companies && companies.length ? 'COMPANY' : 'INDIVIDUAL')">
        Continue
    </button>
</div>