import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { MarkdownComponent, MarkdownService, MarkdownModule } from 'ngx-markdown';
import { SanctionsComponent } from '../sanctions/sanctions.component';


@Component({
  selector: 'sanctions-bill-of-lading',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    SanctionsComponent,
    MarkdownComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full border-1 border-grey px-2" *reportField="'sanctions.billOfLading'; let billOfLading">
        <app-sanctions *ngIf="billOfLading" [data]="billOfLading" [field]="'comment'"></app-sanctions>
      </div>
      <div *ngIf="!billOfLading" class="w-full text-center">
        Currently, there is no recent transaction data available for this entity.
      </div>
    </div>
  `
})
export class SanctionsBillOfLadingComponent {
  @Input() printMode: boolean = false;
  @Input() billOfLading: any;

  convertToMarkdown(text: string): string {
    return text.split('\n').map(line => {
      if (line.startsWith('**') && line.endsWith('**')) {
        return '## ' + line.replace(/\*\*/g, '');
      }
      return line;
    }).join('\n\n');
  }
}