<div class="container mx-auto p-4">
  <div class="mb-8">
    <div class="flex justify-between items-center">
      <div *ngIf="!isEditing" class="flex items-center gap-4">
        <h1 class="text-2xl font-bold">{{ organization?.name }}</h1>
        <button mat-icon-button (click)="toggleEdit()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      
      <form *ngIf="isEditing" [formGroup]="editForm" (ngSubmit)="updateOrganization()" class="flex items-center gap-2">
        <mat-form-field>
          <input matInput formControlName="name" placeholder="Organization name">
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="!editForm.valid">Save</button>
        <button mat-button type="button" (click)="toggleEdit()">Cancel</button>
      </form>

      <button mat-raised-button color="warn" (click)="deleteOrganization()">
        Delete Organization
      </button>
    </div>
  </div>

  <div class="mb-8">
    <h2 class="text-xl font-bold mb-4">Credits</h2>
    <app-credits 
      *ngIf="organization"
      [entityType]="'organization'"
      [entityId]="organization.id">
    </app-credits>
  </div>

  <div class="mb-8">
    <h2 class="text-xl font-bold mb-4">Members</h2>
    <form [formGroup]="addMemberForm" (ngSubmit)="addMember()" class="flex items-center space-x-2 mb-4">
      <mat-form-field class="w-1/2">
        <input type="text"
               placeholder="Search users by name or email"
               matInput
               formControlName="userInput"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" 
                         [displayWith]="displayUserFn">
          <mat-option *ngFor="let user of filteredUsers$ | async" 
                     [value]="user">
            <div class="flex flex-col">
              <span>{{ user.name }}</span>
              <span class="text-sm text-gray-600">{{ user.email }}</span>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="flex items-center">
      <button mat-raised-button 
              color="primary" 
              type="submit" 
              class="p-4"
              [disabled]="!addMemberForm.valid">
        Add Member
      </button>
      </div>
    </form>

    <table mat-table [dataSource]="organization?.members || []" class="w-full">

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let member">{{ member }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let member">
          <button mat-button color="warn" (click)="removeMember(member)">
            remove
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div> 