import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { Organization } from '../../../core/models/organization';
import { User } from '../../../core/models/user';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CreditDialogComponent } from '../../../shared/components/credits-dialog/credits-dialog.component';
import { AddCreditsDialogComponent } from '../../../shared/components/add-credits-dialog/add-credits-dialog.component';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, switchMap } from 'rxjs/operators';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CreditsComponent } from '../../../shared/components/credits/credits.component';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    CreditsComponent
  ]
})
export class OrganizationDetailsComponent implements OnInit {
  organization?: Organization;
  displayedColumns = ['email', 'actions'];
  creditColumns = ['amount', 'date', 'type'];
  editForm: FormGroup;
  addMemberForm: FormGroup;
  isEditing = false;
  filteredUsers$!: Observable<User[]>;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.editForm = this.fb.group({
      name: ['', Validators.required]
    });
    this.addMemberForm = this.fb.group({
      userInput: ['', [Validators.required]]
    });

    // Setup user filtering based on input
    this.filteredUsers$ = this.addMemberForm.get('userInput')!.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => this.filterUsers(value))
    );
  }

  ngOnInit() {
    const orgId = this.route.snapshot.paramMap.get('id');
    if (orgId) {
      this.loadOrganization(orgId);
    }
  }

  private loadOrganization(id: string) {
    this.apiService.getOrganizationById(id).subscribe(org => {
      this.organization = org;
      this.editForm.patchValue({ name: org.name });
    });
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  updateOrganization() {
    if (!this.organization || !this.editForm.valid) return;
    this.apiService.updateOrganization(
      this.organization.id,
      this.editForm.value
    ).subscribe(() => {
      this.isEditing = false;
      this.loadOrganization(this.organization!.id);
    });
  }

  private filterUsers(value: string): Observable<User[]> {
    const filterValue = value.toLowerCase();
    return this.apiService.getUsers().pipe(
      map(response => response.users.filter((user: User) => 
        (!user.organizations || user.organizations.length === 0) &&
        (user.email.toLowerCase().includes(filterValue) || 
         user.name.toLowerCase().includes(filterValue))
      ))
    );
  }

  displayUserFn(user: User | null): string {
    return user ? `${user.name} (${user.email})` : '';
  }

  addMember() {
    if (!this.organization || !this.addMemberForm.valid) return;
    
    const selectedUser = this.addMemberForm.get('userInput')!.value;
    if (!selectedUser || typeof selectedUser === 'string') return;

    this.apiService.addOrganizationMember(
      this.organization.id,
      selectedUser.email
    ).subscribe(() => {
      this.addMemberForm.reset();
      this.loadOrganization(this.organization!.id);
    });
  }

  removeMember(email: string) {
    if (!this.organization) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you want to remove this member?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService.removeOrganizationMember(this.organization!.id, email)
          .subscribe(() => {
            this.loadOrganization(this.organization!.id);
          });
      }
    });
  }

  deleteOrganization() {
    if (!this.organization) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you want to delete this organization?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.apiService.deleteOrganization(this.organization!.id)
          .subscribe(() => {
            this.router.navigate(['/accounts']);
          });
      }
    });
  }

  openCreditDialog() {
    if (!this.organization) return;
    
    const dialogRef = this.dialog.open(CreditDialogComponent, {
      width: '1000px',
      height: '500px',
      data: { 
        entity: this.organization,
        entityType: 'organization'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadOrganization(this.organization!.id);
      }
    });
  }

  addCredits() {
    const dialogRef = this.dialog.open(AddCreditsDialogComponent, {
      width: '400px',
      data: { organization: this.organization }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadOrganization(this.organization!.id);
      }
    });
  }
} 