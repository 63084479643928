import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PostDiligenceDialogComponent } from '../post-diligence-dialog/post-diligence-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service'
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-companies-grid',
  templateUrl: './companies-grid.component.html',
  styleUrls: ['./companies-grid.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatButtonModule, MatIconModule, FormsModule, ClipboardModule, MatPaginatorModule, MatCheckboxModule],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CompaniesGridComponent {
  router = inject(Router);

  @Input()
  companies = [];

  @Input()
  individuals = [];

  @Input()
  diligences: any;

  @Input()
  isAdmin: boolean | null = false;

  @Input()
  email!: string;

  @Input()
  page: number = 0;

  @Input()
  pageSize: number = 10;

  @Output()
  requestDiligence = new EventEmitter<any>();

  @Output()
  requestDiligenceById = new EventEmitter<any>();

  @Output()
  getCompanies = new EventEmitter<any>();

  @Output()
  getIndividuals = new EventEmitter<any>();

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  columnsToDisplay = ['select', 'name', 'regNo', 'number', 'address', 'locationType', 'companyStatus', 'actions'];
  columnsToDisplayIndividuals = ['select', 'firstName', 'lastName', 'company', 'address', 'country', 'number', 'actions'];

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.companies?.length || this.individuals?.length || 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    if (this.companies?.length) {
      this.selection.select(...this.companies);
    } else if (this.individuals?.length) {
      this.selection.select(...this.individuals);
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  getDiligence(company: any, type: string) {
    const selectedItems = this.selection.selected.length > 0 ? this.selection.selected : [company];
    
    if (this.individuals?.length) {
      const items = selectedItems.map(item => ({
        target: {
          fullName: item.firstName + ' ' + item.lastName,
          country: item.country,
          address: item.address.simpleValue
        },
        ...item,
        country: item.country,
        code: item.peopleId,
        name: item.firstName + ' ' + item.lastName,
        type: type
      }));
      this.requestDiligence.emit(items);
    } else if (this.companies?.length) {
      const items = selectedItems.map(item => ({
        target: {
          companyName: item.name,
          country: item.country,
          address: item.address?.simpleValue,
        },
        ...item,
        country: item.country,
        code: item.safeNo,
        type: type
      }));
      this.requestDiligence.emit(items);
    }
  }

  redirectToSearch(): void {
    this.router.navigate(['/search']);
  }

  getDiligenceById(id: string) {
    this.requestDiligenceById.emit({
      jobId: id
    });
  }
}
