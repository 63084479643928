import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { MarkdownComponent, MarkdownService, MarkdownModule } from 'ngx-markdown';
import { SanctionsComponent } from '../sanctions/sanctions.component';


@Component({
  selector: 'sanctions-media-intelligence',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    MarkdownComponent,
    SanctionsComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full border-1 border-grey px-2" *reportField="'sanctions.media'; let mediaIntelligence">
        <app-sanctions *ngIf="mediaIntelligence" [data]="mediaIntelligence" [field]="'summary'"></app-sanctions>
      </div>
    </div>
  `
})
export class SanctionsMediaIntelligenceComponent {
  @Input() printMode: boolean = false;
}