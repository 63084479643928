import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { NavbarComponent } from '../../shared/components/navbar/navbar.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule, MatButtonModule, NavbarComponent, FooterComponent],
  template: `
    <div class="min-h-full">
      <app-navbar></app-navbar>
      <div class="flex flex-col items-center justify-center min-h-[70vh] px-4">
        <h1 class="text-9xl font-bold text-gray-900">404</h1>
        <p class="text-2xl font-semibold text-gray-600 mt-4">Page Not Found</p>
        <p class="text-gray-500 mt-4 text-center">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <button mat-raised-button color="primary" class="mt-8" (click)="goHome()">
          Go Back Home
        </button>
      </div>
      <app-footer></app-footer>
    </div>
  `,
  styles: []
})
export class NotFoundComponent {
  constructor(private router: Router) {}

  goHome() {
    this.router.navigate(['/']);
  }
} 