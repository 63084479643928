import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CreditsComponent } from '../credits/credits.component';

@Component({
  selector: 'app-credit-dialog',
  template: `
    <h2 mat-dialog-title>Manage Credits</h2>
    <mat-dialog-content>
      <app-credits 
        [entityType]="data.entityType"
        [entityId]="data.entityType === 'user' ? data.entity.email : data.entity.id">
      </app-credits>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="close()">Close</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [MatDialogModule, CreditsComponent]
})
export class CreditDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      entity: any, 
      entityType: 'user' | 'organization' 
    }
  ) {}

  close() {
    this.dialogRef.close();
  }
}