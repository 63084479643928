import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MockDataService {
  private mockCompanies = {
    companies: [
      {
        name: 'Tech Corp Ltd',
        regNo: 'TC123456',
        number: '+1234567890',
        address: { simpleValue: '123 Tech Street, Silicon Valley, CA' },
        locationType: 'Headquarters',
        companyStatus: 'Active',
        safeNo: 'SAFE001',
        country: 'United States'
      },
      {
        name: 'Global Solutions Inc',
        regNo: 'GS789012',
        number: '+1987654321',
        address: { simpleValue: '456 Innovation Ave, New York, NY' },
        locationType: 'Branch',
        companyStatus: 'Active',
        safeNo: 'SAFE002',
        country: 'United States'
      },
      // Add more mock companies as needed
    ],
    total: 2
  };

  getCompanies(): Observable<any> {
    return of(this.mockCompanies);
  }
} 