import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-email-credits-dialog',
  template: `
    <div class="p-6">
      <h2 class="text-xl font-semibold mb-4">Add Credits by Email</h2>
      
      <form [formGroup]="emailForm" (ngSubmit)="submit()" class="flex flex-col gap-4">
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput formControlName="email" placeholder="Enter email address">
          <mat-error *ngIf="emailForm.get('email')?.hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="emailForm.get('email')?.hasError('email')">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <div class="flex justify-end gap-2">
          <button mat-button type="button" (click)="close()">Cancel</button>
          <button mat-raised-button color="primary" type="submit" [disabled]="!emailForm.valid">
            Continue
          </button>
        </div>
      </form>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class EmailCreditsDialogComponent {
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private dialogRef: MatDialogRef<EmailCreditsDialogComponent>) {}

  submit() {
    if (this.emailForm.valid) {
      this.dialogRef.close(this.emailForm.get('email')?.value);
    }
  }

  close() {
    this.dialogRef.close();
  }
} 