<h2 mat-dialog-title>Create Organization</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field class="w-full">
      <mat-label>Organization Name</mat-label>
      <input matInput formControlName="name" required>
      <mat-error *ngIf="form.get('name')?.hasError('required')">
        Organization name is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
      Create
    </button>
  </mat-dialog-actions>
</form> 